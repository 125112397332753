


















































import { Table, TableColumn, Button, Form, FormItem, Select, Option, Checkbox } from 'element-ui'
import { Component, Prop, Vue, Watch } from "vue-property-decorator"
import { Concept, Order } from '@/services/SOLO'
import { translations, notificationAlerts } from '@/mixins'
import Translations from '../../../Translations.vue';
import flatPicker from "vue-flatpickr-component";
import moment from "moment";
import {
  ValidationObserver,
  ValidationProvider,
} from "vee-validate";
import { mapGetters } from 'vuex';

@Component({
  components: {
    [Table.name]: Table,
    [TableColumn.name]: TableColumn,
    [Button.name]: Button,
    [Form.name]: Form,
    [FormItem.name]: FormItem,
    [Select.name]: Select,
    [Option.name]: Option,
    [Checkbox.name]: Checkbox,
    ValidationProvider,
    flatPicker,
    Translations,
  },
  computed: {
    ...mapGetters({
      getLocale: 'app/getLocale',
    })
  },
mixins: [translations, notificationAlerts],
})
export default class CustomerUpdateModal extends Vue {
  @Prop() openModal: boolean = false;
  public getLocale!: string;
  public translate!: Function;
  successNotification!: Function;
  systemErrorNotification!: Function;

  mounted() {
  }

  handleClose() {
    this.$emit('handleClose', false);
  }
  onApply() {
    Concept.applyOpeningHours().then(() => {
      this.successNotification('APPLY OPENING HOURS!', 'OPENING HOURS SUCCESSFULLY APPLIED!');
      this.handleClose()
    });
  }
}
